<template>
    <footer>
        <div class="footer">
            <div class="footer__container">
                <div class="footer__wrapper left">
                    <p class="footer_text">© 2023 roleplayworld.ru</p>
                    <p class="footer_text color-text--gray">GTA V в режиме TEXT Role Play</p>
                </div>
                <img class="footer__img" src="../assets/img/banner.png" alt="footer_banner">
                <div class="footer__wrapper right">
                    <router-link to="/payback">
                        <p class="footer_text" >Положение о возврате средств</p>
                    </router-link>
                    <router-link to="/license">
                        <p class="footer_text" >Пользовательское соглашение</p>
                    </router-link>
                    <router-link to="/feedback">
                        <p class="footer_text" >Обратная связь</p>
                    </router-link>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
   name: "Footer"
}
</script>

<style scoped>
    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 8.8vh;
        background: #202020;
        font-size: 0.85em;
        user-select: none;
    }

    .footer__img {
        width: 15.5vh;
        height: 4vh;
    }

    .footer__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        max-width: 1100px;
        height: 100%;
        margin: 0 auto;
    }

    .footer__wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: 10px;
    }

    .footer__wrapper.left {
        text-align: start;
    }

    .footer__wrapper.right {
        text-align: end;
    }

    .footer_text {
        color: #ffffff;
        padding-top: 5px;
    }

    .color-text--gray {
        color: #b6a690;
    }

    /* Мобильные устройства */
    @media only screen and (max-width: 767px) {

        .footer__wrapper.left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .footer__container {
            display: flex;
            justify-content: center;
        }
        .footer__img {
            display: none;
        }
        
        .footer__wrapper.right {
            display: none;
        }

        .footer__wrapper {
            text-align: center;
        }
    }

    /* Планшеты и маленькие настольные экраны */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
    }

    /* Настольные компьютеры */
    @media only screen and (min-width: 992px) {
    }
</style>