import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: '/',
    meta: {
      title: 'Главная - Role Play World'
    },
    component: () => import('@/components/Main.vue')
  },
  {
    path: '/forum',
    name: 'forum'
  },
  {
    path: '/donate',
    name: 'donate',
    meta: {
      title: 'Донат - Role Play World'
    },
    component: () => import('@/components/Donate.vue')
  },
  {
    path: '/payback',
    name: 'payback',
    meta: {
      title: 'Возврат средств - Role Play World'
    },
    component: () => import('@/components/Payback.vue')
  },
  {
    path: '/license',
    name: 'license',
    meta: {
      title: 'Пользовательское соглашение - Role Play World'
    },
    component: () => import('@/components/License.vue')
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: {
      title: 'Обратная связь - Role Play World'
    },
    component: () => import('@/components/Feedback.vue')
  },
  {
    path: "/forum",
    name: 'forum',
    beforeEnter(to, from, next) {
      window.location.href = "https://forum.roleplayworld.ru";
    }
  },
  {
    path: "/ragemp",
    name: 'ragemp',
    beforeEnter(to, from, next) {
      window.location.href = "https://rage.mp";
    }
  },
  {
    path: "/buygame",
    name: "buygame",
    beforeEnter(to, from, next) {
      window.location.href = "https://store.steampowered.com/agecheck/app/271590/";
    }
  },
  {
    path: "/resources",
    name: 'resources',
    meta: {
      title: 'Ресурсы проекта - Role Play World'
    },
    component: () => import('@/components/Resources.vue')
  },
  {
    path: '/play',
    name: 'play',
    meta: {
      title: 'Начать играть - Role Play World'
    },
    component: () => import('@/components/Play.vue')
  },
  {
    path: "/resources/youtube",
    name: 'youtube',
    beforeEnter(to, from, next) {
      window.location.href = "https://www.youtube.com/@roleplayworld1339";
    }
  },
  {
    path: "/resources/vk",
    name: 'vk',
    beforeEnter(to, from, next) {
      window.location.href = "https://vk.com/roleplayworldgta";
    }
  },
  {
    path: "/resources/discord",
    name: 'discord',
    beforeEnter(to, from, next) {
      window.location.href = "https://discord.com/invite/zWmH9CZ7XT";
    }
  },
  {
    path: "/resources/lifeinvader",
    name: 'lifeinvader',
    beforeEnter(to, from, next) {
      window.location.href = "https://li.roleplayworld.ru/";
    }
  },
  {
    path: "/resources/fortune",
    name: 'fortune',
    beforeEnter(to, from, next) {
      window.location.href = "https://fortune.roleplayworld.ru/";
    }
  }, 
  {
    path: "/resources/sscreator",
    name: 'sscreator',
    beforeEnter(to, from, next) {
      window.location.href = "https://ss.roleplayworld.ru/";
    }
  },

  {
    path: "/payments/result/success",
    name: 'success',
    meta: {
      title: 'Донат зачислен! - Role Play World'
    },

    component: () => import('@/components/paymentResults/Success.vue')
  },

  {
    path: "/payments/result/failure",
    name: 'failure',
    meta: {
      title: 'Ошибка доната! - Role Play World'
    },

    component: () => import('@/components/paymentResults/Failure.vue')
  },

  {
    path: '/:pathMatch(.*)*',
    name: '/',
    meta: {
      title: 'Главная - Role Play World'
    },
    component: () => import('@/components/Main.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from) => {
  document.title = to.meta?.title ?? 'Role Play World'
});

export default router