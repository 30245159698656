<template>
    <header>
        <div class="header">
            <div class="header__container">
                <div class="header-banner__wrapper">
                    <a href="/">
                        <img class="header__img" src="../assets/img/banner.png" alt="logo.png">
                    </a>
                </div>
                <nav class="header-nav__menu">
                    <router-link to="/forum">
                        <li class="nav__element">Форум</li>
                    </router-link>
                    <router-link to="/resources">
                        <li class="nav__element">Ресурсы</li>
                    </router-link>
                    <router-link to="/donate">
                        <li class="nav__element">Донат</li>
                    </router-link>
                    <router-link to="/play">
                         <li class="nav__element">Начать играть</li>
                    </router-link>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "Header"
    }
</script>

<style scoped>
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 7.5vh;
        background-color: #202020;
        font-size: 0.9em;
        user-select: none;
    }

    .header__container {
        display: flex;
        align-items: center;
        width: 90%;
        max-width: 1100px;
        height: 100%;
        margin: 0 auto;
    }

    .header-banner__wrapper {
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header__img {
        width: 15.5vh;
        height: 4vh;
    }

    .header-nav__menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
    }

    .nav__element {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7.5vh;
        min-width: 80px;
        font-weight: 500;
        color: #ffffff;
    }

    
    .nav__element:hover {
        color: #138cdb;
        border-bottom: 4px solid #138cdb;
        cursor: pointer;
    }

    /* Мобильные устройства */
    @media only screen and (max-width: 767px) {
        .header__container {
            flex-direction: column;
        }   

        .header__img {
            width: 20vw;
            height: auto;
        }

        .header-nav__menu {
            flex-direction: row;
            justify-content: space-between;
        }

        .nav__element {
            text-align: center;
            height: 38px;
        }

         .nav__element:hover {
            color: #138cdb;
            border-bottom: 4px solid #138cdb;
            cursor: pointer;
        }

    }

    /* Планшеты и маленькие настольные экраны */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .nav__element {
            min-width: 100%;
            text-align: center;
        }
    }
</style>