import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toaster from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

const app = createApp(App)

app.use(Toaster, {
  position: "bottom-right",
  duration: 4500,
});

app.use(store).use(router).mount('#app')