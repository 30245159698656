<template>
  <HeaderVue/>
  <main class="main">
    <router-view/>
  </main>
  <FooterVue/>
</template>

<script>
  import HeaderVue from '@/components/Header.vue';
  import FooterVue from '@/components/Footer.vue';
  import './assets/css/reset.css';
  import './assets/css/general.css';
  
  export default {
    components: {
      HeaderVue,
      FooterVue
    }
  }
</script>

<style>
  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 83.7vh;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(19, 140, 219, 0.5);
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
  }

  ::-webkit-scrollbar-track {
    background: #353431;
  }

  body {
    height: 100%;
    margin: 0;
    background-image: url('./assets/img/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
  }

  /* Мобильные устройства */
  @media only screen and (max-width: 767px) {
    body {
      font-size: 14px;
    }
  }

  /* Планшеты и маленькие настольные экраны */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    body {
      font-size: 16px;
    }
  }
</style>